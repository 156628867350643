<template>
  <div class="UserRole">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell-group class="mt10" v-for="(item, idx) in equList" :key="idx">
        <van-cell>
          <template #title>            
            <van-row type="flex" justify="space-between">              
              <van-col span="12"><h4 class="custom-title">{{item.equipment_code}}</h4></van-col>              
              <van-col span="12"><h4 class="custom-title">{{item.equipment_name}}</h4></van-col>
            </van-row>
            <van-row type="flex" justify="space-between">
              <van-col span="12"><span class="custom-title">设备型号：{{item.equipment_model}}</span></van-col>
              <van-col span="12"><span class="custom-title">生产日期：{{item.product_date}}</span></van-col>
            </van-row>
            <van-row type="flex" justify="space-between">
              <van-col span="24"><span class="custom-title">生产厂家：{{item.equipment_supplier}}</span></van-col>             
            </van-row>
            <van-row type="flex" justify="space-between">
              <van-col span="24"><span class="custom-title">备注：{{item.remark}}</span></van-col>             
            </van-row>            
            <van-row type="flex" justify="end">             
              <van-button plain hairline round type="info" size="mini" @click="toEdit(item.id)">编辑</van-button>
              <van-button plain hairline round type="danger" size="mini" @click='delBtn(item.id,item.equipment_name)'>删除</van-button>
            </van-row>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>    
    <!-- 编辑设备 -->
    <van-popup v-model="equShow" class="elasticlayer tac" :closeable='true' position='bottom' round>
      <h3 class="tac">编辑设备</h3>
      <van-cell-group inset height='300px'>      
        <van-field v-model="equipmentCode" label="设备型号" placeholder="请输入设备编号" readonly />        
        <van-field v-model="equipmentName" label="设备名称" placeholder="请输入设备名称"  />       
        <van-field v-model="equipmentModel" label="设备型号" placeholder="请输入设备型号"  />        
        <van-field v-model="equipmentSupplier" label="生产厂家" placeholder="请输入生产厂家"  />
        <van-field v-model="productDate" label="生产日期" placeholder="请输入生产日期" readonly />         
        <van-field v-model="remark" label="备注" placeholder="请输入备注"  />
      </van-cell-group>
      <van-button class="pre-btn" round block size="small" type="primary" @click="preservEdit" style="position: absolute;bottom: 30px;">保 存</van-button>
    </van-popup>
    <!-- 新增设备 -->
    <van-popup v-model="addShow" v-if="addShow" class="elasticlayer tac" :closeable='true' position='bottom' round>
      <h3 class="tac">新增设备</h3>
      <van-cell-group inset height='300px'>              
        <van-field v-model="equipmentName" label="设备名称" placeholder="请输入设备名称"  />       
        <van-field v-model="equipmentModel" label="设备型号" placeholder="请输入设备型号"  />        
        <van-field v-model="equipmentSupplier" label="生产厂家" placeholder="请输入生产厂家"  /> 
        <van-field :value="date" label="生产日期" placeholder="请输入生产日期"  @click="dateShow = true" />         
        <van-calendar v-if="dateShow"  v-model="dateShow" @confirm="onConfirm"  />    
        <van-field v-model="remark" label="备注" placeholder="请输入备注"  />
      </van-cell-group>
      <van-button class="pre-btn" round block size="small" type="primary" @click="preservAdd" style="position: absolute;bottom: 30px;">保 存</van-button>
    </van-popup>    
    <div class="add-one" title="新增设备" @click="addOne">
      <van-icon name="plus" color="red" size="50" />
    </div>       
  </div>
</template>
<script>
import Vue from 'vue';
import { List, Cell, CellGroup, Tag, Col, Row, Button, Popup, Field, Toast, Dialog, Icon, Calendar } from 'vant';
import collectSticker$ from 'dingtalk-jsapi/api/biz/chat/collectSticker';
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Calendar);
export default {
  name: 'UserRole',
  data() {    
    return {
      page: 1,
      offset: 1,
      count: 2,
      equList: [],
      loading: false,
      finished: false,
      equShow: false,
      addShow: false,
      date: '',
      dateShow: false, //时间插件
      equId: '', //id
      equipmentCode: '', //设备编号
      equipmentName: '', //设备名称
      equipmentModel: '', //设备型号
      equipmentSupplier: '', //生产厂家
      productDate: '', //生产日期
      remark: '' //备注
    };
  }, 
  created: function () {
    
  },
  methods: {
    onLoad () {
      var params = {
        page: this.page++,
        offset: this.offset,
        equipment_name: ''
      }
      this.$get('equipment/get-equipment-list', 'api', params).then(res => {
        var data = res.data.list
        console.log(res)
        var length1 = res.data.list.length
        var length2 = res.data.count
        this.count = res.data.count
        this.loading = false;
        this.equList = this.equList.concat(data);//追加数据           
        if (length1 >= length2) {  //数据全部加载完成
          this.finished = true;         
        }
      }).catch(err => {
        console.log(err)
        this.finished = true;
      })      
    },
    formatDate (date) {     
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }, 
    // 时间
    onConfirm (value) {
      this.dateShow = false
      this.date = this.formatDate(value);
      this.productDate = this.formatDate(value);
    },
    // 编辑
    toEdit(id) {      
      var that = this           
      this.equShow = true 
      this.equId = id         
      that.$post('equipment/get-equipment-detail', 'api', { id: id}).then(res => {         
        var data = res.data   
        console.log(data)
        that.equipmentCode = data.equipment_code, 
        that.equipmentName = data.equipment_name, 
        that.equipmentModel = data.equipment_model, 
        that.equipmentSupplier = data.equipment_supplier,
        that.productDate = data.product_date, 
        that.remark = data.remark 
       
      }, err => {
        console.log(err)
        Toast.fail(err.message);
      })               
    },
    // 编辑保存
    preservEdit(id) {
      console.log(id) 
      var that = this
      var params = {
        id: this.equId,
        equipment_name: this.equipmentName,
        equipment_model: this.equipmentModel,
        equipment_supplier: this.equipmentSupplier,
        product_date: this.productDate,
        remark: this.remark
      }
      console.log(params)
      this.equShow = false    
      that.$post('equipment/save-equipment', 'api', params).then(res => {         
        Toast.success(res.message);         
        that.page = 1
        that.equList = []
        this.finished = false;
        this.loading = true 
        that.onLoad()
      }, err => {
        console.log(err)
        Toast.fail(err.message);
      })
    },
    // 删除
    delBtn(id,name) {
      var that = this    
      Dialog.confirm({
        title: name,
        message:'确定要删除此设备',        
      }).then(() => {
        that.$post('equipment/delete-equipment', 'api', { id: id}).then(res => {     
          Toast.success(res.message);
          that.page = 1
          that.equList = []
          this.finished = false;
          this.loading = true 
          that.onLoad()
        }, err => {
          console.log(err)
          Toast.fail(err.message);
        })
      }).catch(() => {
        // on cancel
      });
    },
    // 新增设备
    addOne() {      
      this.addShow = true  
      this.dateShow = false
      this.equipmentCode = '',
      this.equipmentName ='', 
      this.equipmentModel = '',
      this.equipmentSupplier = '', 
      this.remark = '' 
    },
    // 新增保存
    preservAdd() {
      var that = this
      var params = {
        equipment_name: this.equipmentName,
        equipment_model: this.equipmentModel,
        equipment_supplier: this.equipmentSupplier,
        product_date: this.productDate,
        remark: this.remark
      }
      console.log(params)
      this.addShow = false    
      that.$post('equipment/add-equipment', 'api', params).then(res => {         
        Toast.success(res.message);         
        that.page = 1
        that.equList = []
        this.finished = false;
        this.loading = true 
        that.onLoad()
      }, err => {
        console.log(err)
        Toast.fail(err.message);
      })
    }
  }
};
</script>
<style scoped>
 .status{
  position: absolute;
  top: 10px;
  left: 10px;
  width: 42px;
  height: 42px;
  z-index: 0;
  opacity: 40%;
  transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg);
 }
 .border-tb{
  border-top: dashed 1px #f5f5f5;
  border-bottom: dashed 1px #f5f5f5;
  padding: 8px 0;
  margin: 8px 0;
 }
 .elasticlayer {
   height: 60%;
 }
 .tac {
   text-align: center;
 }
 .pre-btn {
   margin-top: 50px;
 }
 .add-one {
   width: 50px;
   height: 50px;
   background: wheat;
   position: fixed;
   bottom: 100px;
   z-index: 999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
 }
</style>